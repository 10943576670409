import React, { useEffect, useState } from "react";
import { api } from "../../utils";
import "./schedule-table.css";
import SEMANA from "../../enums/semana";
const ScheduleTable = () => {
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    async function getHorarios() {
      const response = await api.getHorarios();
      setSchedules(response.data);
    }

    getHorarios();
  }, []);

  return (
    <div className={`table-wrapper`}>
      {schedules.map((item, key) => {
        if (item.horarios.length > 0) {
          return (
            <table key={key} className="alt">
              <thead>
                <tr>
                  <th colSpan="2">{SEMANA[item.dia]}</th>
                </tr>
              </thead>
              <tbody>
                {item.horarios.map((treino) => {
                  return (
                    <React.Fragment key={treino.id}>
                      <tr>
                        <td width="50%">{treino.horario}</td>
                        <td>{treino.turmas.nome}</td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default ScheduleTable;
