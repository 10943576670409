import axios from "axios";
import config from "../config/ambientes";

const request = axios.create({
  baseURL: `${config.API_URL}/site`,
});

const getHorarios = async () => {
  return await request.get(
    "https://nura-bjj-api-c848f5f4dd05.herokuapp.com/site/horarios"
  );
};

const getProfessores = async (params) => {
  return await request.get("/professores", {
    params: params,
  });
};

const getModalidades = async (params) => {
  return await request.get("/modalidades", {
    params: params,
  });
};

const getPlanos = async (params) => {
  return await request.get("/planos", {
    params: params,
  });
};

const getProdutos = async (params) => {
  return await request.get("/produtos", {
    params: params,
  });
};

const api = {
  getHorarios,
  getProfessores,
  getModalidades,
  getPlanos,
  getProdutos,
};

export default api;
